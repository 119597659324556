import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import MuiSlider from '../mui/MuiSlider';
import InputAdornment from '@mui/material/InputAdornment';
import { MuiTextEntry } from '../mui/MuiTextEntry';
import MuiSelect from '../mui/MuiSelect';
import ScenarioDetails from '../ScenarioDetails';
import Disclaimer from '../Disclaimer';
import Button from '@mui/material/Button';
import {
    LargeHeadlineDSK,
    LargeLabelDSK,
    LargeTitleTextDSK,
    LargeDisplayTextDSK,
    LargeBodyTextDSK,
    SkewText,
    OutputText,
    AnnualRevenueLabelDSK,
    GoldLabelDSK
} from '../mui/MuiText';
import '../../styles/appDesktop.scss';
import '../../styles/appCommon.scss';
import useCalcCardAdvanced from '../../hooks/useCalcCardAdvanced';
import {
    valMultipleMarks,
    annualRevenueConfig,
    offerPercentConfig,
    goldPriceConfig,
    skewConfig
} from '../../calcConfig';
import {
    ANNUAL_REVENUE_TEXT,
    HEADER_SUBTITLE_ADV,
    HEADER_TITLE,
    OFFER_PERCENT_TEXT,
    TARGET_LABEL,
    TARGET_TITLE,
    VALUATION_LABEL,
    VALUATION_TITLE,
    VAL_MULTIPLE_TEXT,
    GOLD_PRICE,
    SKEW_TITLE,
    SKEW_GOLD,
    SKEW_DIAMOND
} from '../../textStrings';
import useInitialValues, { IInitialValues } from '../../hooks/useInitialValues';
import SimpleSendToInbox from '../SimpleSendToInbox';

const StyledCard = styled(Card)({
    backgroundColor: 'rgba(13, 13, 13, 0.9)',
    borderColor: '#00FAB9',
    borderWidth: '1px',
    borderStyle: 'solid',
    color: 'white',
    padding: '24px',
    maxWidth: '95%',
    overflow: 'hidden',
    backdropFilter: 'blur(8px)',
    borderRadius: 'var(--spacing-3, 16px) var(--spacing-3, 16px) var(--spacing-3, 16px) var(--spacing-3, 16px)'
});

function CalcCardDesktop(): React.ReactElement {
    const initialValues: IInitialValues = useInitialValues();

    const {
        annualRevenueOutput,
        offerPercentOutput,
        valMultipleLabel,
        offerAmountOutput,
        valuationOutput,
        solutions,
        handleAnnualRevenueChange,
        handleOfferPercentChange,
        handleValMultipleChange,
        handleGoldPriceChange,
        handleSkewChange
    } = useCalcCardAdvanced(initialValues);

    return (
        <StyledCard className='styled-card-dsk'>
            <CardContent className='card-top-dsk'>
                <CardContent className='card-header-dsk center'>
                    <LargeHeadlineDSK align='center' paddingBottom='10px'>
                        {HEADER_TITLE}
                    </LargeHeadlineDSK>
                    <LargeLabelDSK align='center'>{HEADER_SUBTITLE_ADV}</LargeLabelDSK>
                </CardContent>
                <CardContent className='card-input-main-dsk center'>
                    <div className='card-input-content-dsk center'>
                        <AnnualRevenueLabelDSK align='center' className='card-input-title-dsk'>
                            {ANNUAL_REVENUE_TEXT}
                        </AnnualRevenueLabelDSK>
                        <MuiTextEntry
                            className='card-text-entry-mb'
                            defaultValue={initialValues.annualRevenue.toLocaleString()}
                            useError={true}
                            minRange={annualRevenueConfig.min}
                            maxRange={annualRevenueConfig.max}
                            allowOnlyNumbers={true}
                            formatWithCommas={true}
                            errorText={`Must be between $${annualRevenueConfig.min.toLocaleString()} and $${annualRevenueConfig.max.toLocaleString()}`}
                            onChange={handleAnnualRevenueChange}
                            InputProps={{
                                startAdornment: <InputAdornment position='start'>$</InputAdornment>
                            }}
                        />
                    </div>
                    <div className='card-input-content-dsk center'>
                        <LargeLabelDSK align='center' className='card-input-title-dsk'>
                            {OFFER_PERCENT_TEXT}
                        </LargeLabelDSK>
                        <MuiTextEntry
                            className='card-text-entry-mb'
                            defaultValue={initialValues.offerPercent.toLocaleString()}
                            useError={true}
                            allowOnlyNumbers={true}
                            formatWithCommas={true}
                            minRange={offerPercentConfig.min}
                            maxRange={offerPercentConfig.max}
                            errorText={`Must be between ${offerPercentConfig.min.toLocaleString()}% and ${offerPercentConfig.max.toLocaleString()}%`}
                            onChange={handleOfferPercentChange}
                            InputProps={{
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>
                            }}
                        />
                    </div>
                    <div className='card-input-content-dsk center'>
                        <LargeLabelDSK align='center' className='card-input-title-dsk'>
                            {VAL_MULTIPLE_TEXT}
                        </LargeLabelDSK>
                        <MuiSelect
                            onChange={handleValMultipleChange}
                            className='card-text-entry-mb'
                            options={valMultipleMarks}
                            defaultValue={initialValues.valMultiple}
                        />
                    </div>
                    <div className='card-input-content-dsk center'>
                        <GoldLabelDSK align='center' className='card-input-title-dsk'>
                            {GOLD_PRICE}
                        </GoldLabelDSK>
                        <MuiTextEntry
                            className='card-text-entry-mb'
                            defaultValue={initialValues.goldPrice.toLocaleString()}
                            allowOnlyNumbers={true}
                            formatWithCommas={true}
                            useError={true}
                            minRange={goldPriceConfig.min}
                            maxRange={goldPriceConfig.max}
                            errorText={`Must be between ${goldPriceConfig.min.toLocaleString()} and ${goldPriceConfig.max.toLocaleString()}`}
                            onChange={handleGoldPriceChange}
                            InputProps={{
                                startAdornment: <InputAdornment position='start'>$</InputAdornment>
                            }}
                        />
                    </div>
                </CardContent>
            </CardContent>
            <CardContent className='card-bottom-dsk'>
                <div className='card-bottom-output-dsk'>
                    <LargeTitleTextDSK align='center' paddingBottom='8px'>
                        {TARGET_TITLE}
                    </LargeTitleTextDSK>
                    <LargeDisplayTextDSK align='center' paddingBottom='16px'>
                        {offerAmountOutput}
                    </LargeDisplayTextDSK>
                    <LargeBodyTextDSK align='center'>{TARGET_LABEL}</LargeBodyTextDSK>
                </div>
                <div className='card-bottom-output-dsk'>
                    <LargeTitleTextDSK align='center' paddingBottom='8px'>
                        {VALUATION_TITLE}
                    </LargeTitleTextDSK>
                    <LargeDisplayTextDSK align='center' paddingBottom='16px'>
                        {valuationOutput}
                    </LargeDisplayTextDSK>
                    <LargeBodyTextDSK align='center'>{VALUATION_LABEL}</LargeBodyTextDSK>
                </div>
            </CardContent>
            <CardContent className='calc-rsu-skew-dsk center'>
                <OutputText>{SKEW_TITLE}</OutputText>
                <MuiSlider
                    min={skewConfig.min}
                    max={skewConfig.max}
                    step={skewConfig.step}
                    defaultValue={0}
                    onValueChange={handleSkewChange}
                />
                <div className='calc-skew-text-dsk'>
                    <SkewText align='left'>{SKEW_GOLD}</SkewText>
                    <SkewText align='right'>{SKEW_DIAMOND}</SkewText>
                </div>
            </CardContent>
            <div className='calc-advanced-output-dsk center'>
                <ScenarioDetails solution={solutions[0]} />
            </div>
            <CardContent className='email-component'>
                <SimpleSendToInbox
                    annualRevenue={annualRevenueOutput}
                    dropCalculatorLink={window.location.href}
                    offerPercent={offerPercentOutput}
                    valuationMultiple={valMultipleLabel}
                    fundingTarget={offerAmountOutput}
                    channelValuation={valuationOutput}
                />
            </CardContent>
            <CardContent className='disclaimer-component'>
                <Disclaimer />
            </CardContent>
            <CardContent className='card-button-dsk center'>
                <Button
                    variant='contained'
                    href='https://gigastarmarket.io/apply-for-a-drop'
                    color='primary'
                    fullWidth
                    sx={{
                        height: '100%',
                        backgroundColor: '#00FAB9',
                        color: '#0D0D0D',
                        fontSize: '18px',
                        fontWeight: '600',
                        borderRadius: '0 0 var(--spacing-3, 16px) var(--spacing-3, 16px)',
                        paddingBottom: '10px',
                        '&:hover': {
                            backgroundColor: '#8AFFE0'
                        }
                    }}
                >
                    Apply For a Drop
                </Button>
            </CardContent>
        </StyledCard>
    );
}

export default CalcCardDesktop;
