import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import MuiSlider from '../mui/MuiSlider';
import MuiSliderDiscrete from '../mui/MuiSliderDiscrete';
import Button from '@mui/material/Button';
import {
    LargeHeadlineDSK,
    LargeLabelDSK,
    SliderText,
    LargeTitleTextDSK,
    LargeDisplayTextDSK,
    LargeBodyTextDSK
} from '../mui/MuiText';
import '../../styles/appDesktop.scss';
import '../../styles/appCommon.scss';
import useCalcCard from '../../hooks/useCalcCard';
import { annualRevenueOptions, offerPercentMarks, valMultipleMarks } from '../../calcConfig';
import {
    ANNUAL_REVENUE_INFO,
    OFFER_PERCENT_INFO,
    VALUATION_INFO,
    ANNUAL_REVENUE_TEXT,
    HEADER_SUBTITLE_BASIC,
    HEADER_TITLE,
    OFFER_PERCENT_TEXT,
    TARGET_LABEL,
    TARGET_TITLE,
    VALUATION_LABEL,
    VALUATION_TITLE,
    VAL_MULTIPLE_TEXT
} from '../../textStrings';
import useInitialValues from '../../hooks/useInitialValues';
import { Tooltip, Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Disclaimer from '../Disclaimer';
import SimpleSendToInbox from '../SimpleSendToInbox';

const StyledCard = styled(Card)({
    backgroundColor: 'rgba(13, 13, 13, 0.9)',
    borderColor: '#00FAB9',
    borderWidth: '1px',
    borderStyle: 'solid',
    color: 'white',
    padding: '24px',
    maxWidth: '85%',
    overflow: 'hidden',
    backdropFilter: 'blur(8px)',
    borderRadius: 'var(--spacing-3, 16px) var(--spacing-3, 16px) var(--spacing-3, 16px) var(--spacing-3, 16px)'
});

function CalcCardDesktop() {
    const initialValues = useInitialValues();

    const {
        annualRevenueOutput,
        offerPercentOutput,
        offerAmountOutput,
        valuationOutput,
        valMultipleLabel,
        handleAnnualRevenueChange,
        handleOfferPercentChange,
        handleValMultipleChange
    } = useCalcCard(initialValues);

    return (
        <StyledCard className='styled-card-dsk'>
            <CardContent className='card-top-dsk'>
                <CardContent className='card-header-dsk center'>
                    <LargeHeadlineDSK align='center' paddingBottom='10px'>
                        {HEADER_TITLE}
                    </LargeHeadlineDSK>
                    <LargeLabelDSK align='center'>{HEADER_SUBTITLE_BASIC}</LargeLabelDSK>
                </CardContent>
                <CardContent className='card-slider-main-dsk center'>
                    <div className='card-slider-content-dsk center'>
                        <LargeLabelDSK align='center' className='card-slider-title-dsk'>
                            {ANNUAL_REVENUE_TEXT}
                        </LargeLabelDSK>
                        <MuiSlider
                            min={annualRevenueOptions.min}
                            max={annualRevenueOptions.max}
                            step={annualRevenueOptions.step}
                            defaultValue={initialValues.annualRevenue}
                            onValueChange={handleAnnualRevenueChange}
                        />
                        <SliderText align='center'>{annualRevenueOutput}</SliderText>
                        <Tooltip
                            title={
                                <Box>
                                    <ANNUAL_REVENUE_INFO />
                                </Box>
                            }
                            arrow
                        >
                            <InfoOutlinedIcon sx={{ color: 'white', paddingTop: '8px', cursor: 'pointer' }} />
                        </Tooltip>
                    </div>
                    <div className='card-slider-content-dsk center'>
                        <LargeLabelDSK align='center' className='card-slider-title-dsk'>
                            {OFFER_PERCENT_TEXT}
                        </LargeLabelDSK>
                        <MuiSliderDiscrete
                            marks={offerPercentMarks}
                            min={offerPercentMarks[0].value}
                            max={offerPercentMarks.at(-1)!.value}
                            defaultValue={initialValues.offerPercent}
                            onValueChange={handleOfferPercentChange}
                        />
                        <SliderText align='center'>{offerPercentOutput}</SliderText>
                        <Tooltip
                            title={
                                <Box>
                                    <OFFER_PERCENT_INFO />
                                </Box>
                            }
                            arrow
                        >
                            <InfoOutlinedIcon sx={{ color: 'white', paddingTop: '8px', cursor: 'pointer' }} />
                        </Tooltip>
                    </div>
                    <div className='card-slider-content-dsk center'>
                        <LargeLabelDSK align='center' className='card-slider-title-dsk'>
                            {VAL_MULTIPLE_TEXT}
                        </LargeLabelDSK>
                        <MuiSliderDiscrete
                            marks={valMultipleMarks}
                            defaultValue={initialValues.valMultiple}
                            min={valMultipleMarks[0].value}
                            max={valMultipleMarks.at(-1)!.value}
                            onValueChange={handleValMultipleChange}
                        />
                        <SliderText align='center'>{valMultipleLabel}</SliderText>
                        <Tooltip
                            title={
                                <Box>
                                    <VALUATION_INFO />
                                </Box>
                            }
                            arrow
                        >
                            <InfoOutlinedIcon sx={{ color: 'white', paddingTop: '8px', cursor: 'pointer' }} />
                        </Tooltip>
                    </div>
                </CardContent>
            </CardContent>
            <CardContent className='card-bottom-dsk'>
                <div className='card-bottom-output-dsk'>
                    <LargeTitleTextDSK align='center' paddingBottom='8px'>
                        {TARGET_TITLE}
                    </LargeTitleTextDSK>
                    <LargeDisplayTextDSK align='center' paddingBottom='16px'>
                        {offerAmountOutput}
                    </LargeDisplayTextDSK>
                    <LargeBodyTextDSK align='center'>{TARGET_LABEL}</LargeBodyTextDSK>
                </div>
                <div className='card-bottom-output-dsk'>
                    <LargeTitleTextDSK align='center' paddingBottom='8px'>
                        {VALUATION_TITLE}
                    </LargeTitleTextDSK>
                    <LargeDisplayTextDSK align='center' paddingBottom='16px'>
                        {valuationOutput}
                    </LargeDisplayTextDSK>
                    <LargeBodyTextDSK align='center'>{VALUATION_LABEL}</LargeBodyTextDSK>
                </div>
            </CardContent>
            <CardContent className='email-component'>
                <SimpleSendToInbox
                    annualRevenue={annualRevenueOutput}
                    dropCalculatorLink={window.location.href}
                    offerPercent={offerPercentOutput}
                    valuationMultiple={valMultipleLabel}
                    fundingTarget={offerAmountOutput}
                    channelValuation={valuationOutput}
                />
            </CardContent>
            <CardContent className='disclaimer-component'>
                <Disclaimer />
            </CardContent>
            <CardContent className='card-button-dsk center'>
                <Button
                    variant='contained'
                    href='https://gigastarmarket.io/apply-for-a-drop'
                    color='primary'
                    fullWidth
                    sx={{
                        height: '100%',
                        backgroundColor: '#00FAB9',
                        color: '#0D0D0D',
                        fontSize: '18px',
                        fontWeight: '600',
                        borderRadius: '0 0 var(--spacing-3, 16px) var(--spacing-3, 16px)',
                        paddingBottom: '10px',
                        '&:hover': {
                            backgroundColor: '#8AFFE0'
                        }
                    }}
                >
                    Apply For a Drop
                </Button>
            </CardContent>
        </StyledCard>
    );
}

export default CalcCardDesktop;
